@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .form-group {
  }
  .form-label {
    @apply hidden md:block text-sm font-medium text-gray-700;
  }
  .form-control {
    @apply mt-1 focus:ring-primary-500 focus:border-primary-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300;
  }
  .form-control.is-invalid {
    @apply border-red-500 ring-red-500;
  }
  .btn {
    @apply inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500;
  }
  .btn-primary {
    @apply bg-primary-600 hover:bg-primary-700;
  }
  .btn-secondary {
    @apply bg-secondary-600 hover:bg-secondary-700;
  }

  /*placelist*/
  .results {
    @apply divide-y divide-gray-200;
  }
  .results__item {
    @apply px-6 py-5 cursor-pointer hover:bg-gray-50;
  }

  .results--opened .results__item {
    display: none;
  }

  .results--opened .results__item--selected {
    @apply block hover:bg-white;
  }

  .results__item:hover {
  }

  .results__item--selected {
    @apply cursor-auto;
  }

  /*fuel-price*/

  .fuel-price {
    @apply mt-2 flex flex-col w-[90px];
  }
  .fuel-price__price {
    @apply border border-gray-300 border-b-0 rounded-t font-bold px-1 w-full text-sm text-center;
  }
  .fuel-price__label {
    @apply border border-gray-300 rounded-b px-1 w-full text-xs text-center;
    @apply truncate;
  }
  .fuel-price--95 .fuel-price__price {
    @apply bg-fuel-95 text-white;
  }
  .fuel-price--dizel .fuel-price__price {
    @apply bg-fuel-dizel text-white;
  }
}
